import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    user: null,
    time: null,
    accessToken: null,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        startMount(state) {
            state.isLoading = false;
            state.error = null;
        },

        resetError(state) {
            state.error = null;
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.user = null;
            state.time = null;
            state.error = action.payload;
        },

        // LOGIN
        postLoginSuccess(state, action) {
            state.isLoading = false;
            state.user = action.payload;
            state.accessToken = action.payload.accessToken;
            state.time = new Date().getTime();
            state.error = null;
        },
        postLogoutSuccess(state, action) {
            state.isLoading = false;
            state.user = null;
            state.accessToken = null;
            state.error = null;
        },
        postLoginAnonymousSuccess(state, action) {
            state.isLoading = false;
            state.user = null;
            state.accessToken = action.payload;
            state.error = null;
        },

        postChangePasswordSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
        },

        postForgotPasswordSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
        },


    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    postLoginSuccess,
    postLogoutSuccess,
    postLoginAnonymousSuccess,
    postChangePasswordSuccess,

} = slice.actions;

export function startReMount() {
    return async (dispatch) => {
        dispatch(slice.actions.startMount());
    };
}

export function resetError() {
    return async (dispatch) => {
        dispatch(slice.actions.resetError());
    };
}

// ----------------------------------------------------------------------
export function postLogin(username, password) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/v1/users/login/normal`, {
                identity: username,
                password,
            });
            const {accessToken, user} = response.data?.data;
            user.accessToken = accessToken?.token;
            window.localStorage.setItem('accessToken', user.accessToken);
            dispatch(slice.actions.postLoginSuccess(user));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postGoogleLogin(googleToken) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/v1/users/login/google`, {
                accessToken: googleToken,
            });
            const {accessToken, user} = response.data?.data;
            user.accessToken = accessToken?.token;
            window.localStorage.setItem('accessToken', user.accessToken);
            dispatch(slice.actions.postLoginSuccess(user));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postLoginAnonymous(username, password) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/v1/users/login/anonymous`, {});
            const {accessToken} = response.data?.data;
            window.localStorage.setItem('accessToken', accessToken?.token);
            dispatch(slice.actions.postLoginAnonymousSuccess(accessToken?.token));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProfile(accessToken) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/v1/user/profile', {
                headers: {Authorization: `${accessToken}`},
            });
            let user = response.data.data;
            user.accessToken = accessToken;
            dispatch(slice.actions.postLoginSuccess(user));
            return user;
        } catch (error) {
            window.localStorage.removeItem('accessToken');
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postLogout(username, password) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            window.localStorage.removeItem('accessToken');
            dispatch(slice.actions.postLogoutSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function updateProfile(accessToken, newData) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put('/api/v1/user/profile', newData, {
                headers: {
                    Authorization: `${accessToken}`
                },
            });
            let user = response.data.data;
            user.accessToken = accessToken;
            dispatch(slice.actions.postLoginSuccess(user));
            return response
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error
        }
    };
}

export function changePassword(accessToken, oldPassword, newPassword) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/api/v1/user/change_password', {
                "password": oldPassword,
                "newPassword": newPassword
            }, {
                headers: {
                    Authorization: `${accessToken}`
                },
            });
            dispatch(slice.actions.postChangePasswordSuccess());
            return response
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            return error
        }
    };
}

export function forgotPassword(accessToken, email) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put('/api/v1/users/auth/forgot_password', {
                "identify": email,
                "emailLink": true
            }, {
                headers: {
                    Authorization: `${accessToken}`
                }
            });
            dispatch(slice.actions.postForgotPasswordSuccess());
            return response
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            return error
        }
    };
}